<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-8">
      <div class="input-wrap">
        <v-subheader class="input-label">
          Main Keyword
        </v-subheader>
        <v-text-field
          v-model="objectMainKeyword"
          single-line
          outlined
          dense
          hide-details
        />
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Meta Keywords
        </v-subheader>
        <v-combobox
          v-model="objectMetaKeywords"
          chips
          clearable
          multiple
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click:close="removeMetaKeywords(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </div>
      <div class="input-wrap">
        <v-subheader class="input-label">
          Optional Keywords
        </v-subheader>
        <div class="input-wrap__rows">
          <template v-for="(obj, index) in objectOptionalKeywords">
            <v-combobox
              :key="index"
              v-model="objectOptionalKeywords[index].value"
              chips
              clearable
              multiple
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click:close="removeOptionalKeywords(item, index)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </template>
        </div>
      </div>
      <v-btn
        color="teal lighten-2"
        class="ma-0 float-right"
        @click="addOptionalKeyword"
      >
        <v-icon
          left
          color="#fff"
        >
          mdi-plus
        </v-icon>
        Add Optional Keyword
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'

  export default {
    mixins: [validationMixin],
    props: {
      object: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        objectMainKeyword: '',
        objectMetaKeywords: [],
        objectOptionalKeywords: [{
          value: [],
        }],
      }
    },
    created () {
      if (this.object) {
        this.objectMainKeyword = this.object.mainKeyword || ''
        this.objectMetaKeywords = this.object.metaKeywords.split(' ') || []
        this.objectOptionalKeywords[0].value = this.object.optionalKeywords.split(' ') || []
      }
    },
    methods: {
      addOptionalKeyword () {
        this.objectOptionalKeywords.push({ value: '' })
      },
      removeMetaKeywords (item) {
        this.objectMetaKeywords.splice(this.objectMetaKeywords.indexOf(item), 1)
        this.objectMetaKeywords = [...this.objectMetaKeywords]
      },
      removeOptionalKeywords (item, index) {
        const obj = this.objectOptionalKeywords[index].value
        obj.splice(this.objectOptionalKeywords[index].value.indexOf(item), 1)
        this.objectOptionalKeywords[index].value = [...obj]
      },
    },
  }
</script>
